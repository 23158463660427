import React, { useEffect } from 'react';

const RedirectPage = ({ to }) => {
  useEffect(() => {
    window.location.href = to;
  }, []);
  return (<></>);
};

export default RedirectPage;
